<template>
  <Button
    :label="_t('label_select_text_template')"
    class="p-button-success"
    @click="isTemplateModalVisible = true"
  />

  <Dialog v-model:visible="isTemplateModalVisible" style="width: 40em">
    <template #header>
      <h3>{{ _t("label_text_templates") }}</h3>
    </template>
    <p>{{ _t("label_select_user_group") }}</p>
    <Dropdown
      @scroll="getUserGroups"
      @click="getUserGroups"
      v-model="selectedUserGroupIdForTextTemplate"
      :options="userGroups"
      optionLabel="name"
      optionValue="id"
      class="p-m-0"
      :filter="true"
      style="width: 100%"
    />
    <br />
    <br />
    <p>{{ _t("label_select_text_template") }}</p>
    <Dropdown
      :disabled="!selectedUserGroupIdForTextTemplate"
      v-model="selectedTextTemplate"
      :options="textTemplates"
      optionLabel="text"
      optionValue="text"
      class="p-m-0"
      :filter="true"
      style="width: 100%"
    />
    <template #footer>
      <Button
        :label="_t('Cancel')"
        class="p-button-outlined p-button-secondary"
        @click="isTemplateModalVisible = false"
      />
      <Button
        :label="_t('Save')"
        autofocus
        :disabled="selectedTextTemplate.length === 0"
        class="p-button-success"
        @click="handleSave"
      />
    </template>
  </Dialog>
</template>

<script>
import { getList } from "@/api/list";
import { getItemById } from "@/api/text-templates";

export default {
  name: "SelectTextTemplate",
  props: {
    onScrollUserGroups: {
      type: Function,
    },
  },
  emits: ["handleSave"],
  data() {
    return {
      isTemplateModalVisible: false,
      textTemplates: [],
      userGroups: [],
      selectedTextTemplate: "",
      selectedUserGroupIdForTextTemplate: null,
      userGroupPage: 0,
      lazyLoadUGRecords: true,
      orgId: localStorage.getItem("orgId") ?? null,
    };
  },
  methods: {
    handleSave() {
      this.isTemplateModalVisible = false;
      this.$emit("handleSave", this.selectedTextTemplate);
    },
    getUserGroups() {
      if (this.lazyLoadUGRecords) {
        getList("USERGROUP", this.userGroupPage, "", 50, this.orgId).then(
          (response) => {
            if (response.data.list.length === 0) {
              this.lazyLoadUGRecords = false;
            }
            this.userGroups = [...this.userGroups, ...response.data.list];
            this.userGroupPage++;
          }
        );
      }
    },
  },
  watch: {
    selectedUserGroupIdForTextTemplate(value) {
      if (value !== null) {
        getItemById(value)
          .then((userRes) => {
            if (userRes.data) {
              this.textTemplates = userRes.data;
            }
          })
          .catch((err) => console.log("error", err));
      } else {
        this.textTemplates = [];
      }
    },
  },
};
</script>
